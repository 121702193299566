import React from "react"
//import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


// var Spotify = require('spotify-web-api-js');
// var s = new Spotify()
// var spotifyApi = new SpotifyWebApi();
// spotifyApi.setAccessToken(`AQDYc3MnKeH0hvkEFqsXNOxwwswbhAphtag6ik4r-kAzpPPoslHILAvvPLgk3I6RmmmYj3vM9mYiIiPMrDyoV75EeWX4W4Ad1y4LrDiARugSkA5J-9qX_27vPPC-asq-r5c`);

const SpotifyPage = () => (
    <Layout>
        <SEO title="My Spotify"/>
        <h1>My Favorite Songs</h1>
        <div style={{maxWidth: `900px`, overflow: "auto"}}>
            <div style={{display: "inline-block", maxWidth: `620px`}}>
                <p>Welcome to my spotify page!</p>
                <p>Spotify player coming soon.</p>


            </div>

        </div>
    </Layout>
)

export default SpotifyPage
